import React from "react";

import Heading from "../../atoms/Heading";

import { ITechCardProps } from "./types";

import * as s from "./styles.module.scss";

export default function TechCard(props: ITechCardProps) {
    const {
        locations,
        cameras,
        sensorCoolingTemp,
        filters,
        telescopes,
        lenses,
        correctingLenses,
        mounts,
        guidingScopes,
        guidingCameras,
        guidingDevices,
        imageQuality,
        exposure,
        lightFrames,
        integration,
        calibFrames,
        software,
        preProcessing,
        processing,
    } = props;

    return (
        <>
            <div className={s.techItem}>
                <Heading level={3} size="xsmall" className={s.techHeading}>
                    {locations.length > 1
                        ? " Dates / Locations"
                        : "Date / Location"}
                </Heading>
                {locations.map((location, index) => (
                    <p key={`tech-location-${index}`}>{location}</p>
                ))}
            </div>
            <div className={s.techItem}>
                <Heading level={3} size="xsmall" className={s.techHeading}>
                    Equipment
                </Heading>
                <p>
                    <span className={s.subTitle}>
                        {cameras.length > 1 ? "Cameras: " : "Camera: "}
                    </span>
                    {cameras.map((camera, index) => (
                        <span key={`tech-camera-${index}`}>
                            {index ? ", " : ""}
                            {camera}
                        </span>
                    ))}
                </p>
                {sensorCoolingTemp && (
                    <p>
                        <span className={s.subTitle}>
                            Sensor Cooling Temperature:
                        </span>{" "}
                        {sensorCoolingTemp}
                    </p>
                )}
                {filters && (
                    <p>
                        <span className={s.subTitle}>
                            {filters.length > 1 ? "Filters: " : "Filter: "}
                        </span>
                        {filters.map((filter, index) => (
                            <span key={`tech-filter-${index}`}>
                                {index ? ", " : ""}
                                {filter}
                            </span>
                        ))}
                    </p>
                )}
                {telescopes && (
                    <p>
                        <span className={s.subTitle}>
                            {telescopes.length > 1
                                ? "Imaging Telescopes: "
                                : "Imaging Telescope: "}
                        </span>
                        {telescopes.map((scope, index) => (
                            <span key={`tech-scope-${index}`}>
                                {index ? ", " : ""}
                                {scope}
                            </span>
                        ))}
                    </p>
                )}
                {lenses && (
                    <p>
                        <span className={s.subTitle}>
                            {lenses.length > 1 ? "Lenses: " : "Lens: "}
                        </span>
                        {lenses.map((lens, index) => (
                            <span key={`tech-lens-${index}`}>
                                {index ? ", " : ""}
                                {lens}
                            </span>
                        ))}
                    </p>
                )}
                {correctingLenses && (
                    <p>
                        <span className={s.subTitle}>
                            {correctingLenses.length > 1
                                ? "Correcting Lenses: "
                                : "Correcting Lens: "}
                        </span>
                        {correctingLenses.map((cLens, index) => (
                            <span key={`tech-cLens-${index}`}>
                                {index ? ", " : ""}
                                {cLens}
                            </span>
                        ))}
                    </p>
                )}
                {mounts && (
                    <p>
                        <span className={s.subTitle}>
                            {mounts.length > 1 ? "Mounts: " : "Mount: "}
                        </span>
                        {mounts.map((mount, index) => (
                            <span key={`tech-mount-${index}`}>
                                {index ? ", " : ""}
                                {mount}
                            </span>
                        ))}
                    </p>
                )}
            </div>
            {guidingScopes && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        {guidingScopes.length > 1
                            ? "Guiding Scopes or Lenses"
                            : "Guiding Scope or Lens"}
                    </Heading>
                    {guidingScopes.map((gScope, index) => (
                        <p key={`tech-gScope-${index}`}>{gScope}</p>
                    ))}
                </div>
            )}
            {guidingCameras && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        {guidingScopes.length > 1
                            ? "Guiding Cameras"
                            : "Guiding Camera"}
                    </Heading>
                    {guidingScopes.map((gCamera, index) => (
                        <p key={`tech-gCamera-${index}`}>{gCamera}</p>
                    ))}
                </div>
            )}
            {guidingDevices && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        {guidingDevices.length > 1
                            ? "Guiding Devices"
                            : "Guiding Device"}
                    </Heading>
                    {guidingDevices.map((gDevice, index) => (
                        <p key={`tech-gDevice-${index}`}>{gDevice}</p>
                    ))}
                </div>
            )}
            <div className={s.techItem}>
                <Heading level={3} size="xsmall" className={s.techHeading}>
                    Image Quality
                </Heading>
                <p>{imageQuality}</p>
            </div>
            {exposure && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        Exposure
                    </Heading>
                    <p>{exposure}</p>
                </div>
            )}
            <div className={s.techItem}>
                <Heading level={3} size="xsmall" className={s.techHeading}>
                    Exposure time per image / number of composites and
                    sensitivity
                </Heading>
                {lightFrames.map((lFrame, index) => (
                    <p key={`tech-lFrame-${index}`}>{lFrame}</p>
                ))}
            </div>
            {integration && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        Total Integration Time
                    </Heading>
                    <p>{integration}</p>
                </div>
            )}
            {calibFrames && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        Calibration Frames
                    </Heading>
                    {calibFrames.map((cFrame, index) => (
                        <p key={`tech-location-${index}`}>{cFrame}</p>
                    ))}
                </div>
            )}
            <div className={s.techItem}>
                <Heading level={3} size="xsmall" className={s.techHeading}>
                    Software
                </Heading>
                {software.map((app, index) => (
                    <p key={`tech-software-${index}`}>{app}</p>
                ))}
            </div>
            {preProcessing && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        Preprocessing
                    </Heading>
                    {preProcessing.map((technique, index) => (
                        <p key={`tech-preprocessing-${index}`}>{technique}</p>
                    ))}
                </div>
            )}
            {processing && (
                <div className={s.techItem}>
                    <Heading level={3} size="xsmall" className={s.techHeading}>
                        Processing
                    </Heading>
                    {processing.map((technique, index) => (
                        <p key={`tech-processing-${index}`}>{technique}</p>
                    ))}
                </div>
            )}
        </>
    );
}
