import React from "react";
import classnames from "classnames";

import { IExpandProps } from "./types";

import * as s from "./styles.module.scss";

export default function Expand(props: IExpandProps) {
    const { index, callbackFn, superCard = false } = props;
    return (
        <div
            className={classnames(s.expand, {
                [s.superCard]: superCard,
            })}
        >
            <button onClick={() => callbackFn(index)}>Click to Expand</button>
        </div>
    );
}
