import React, { useState } from "react";
import { graphql } from "gatsby";
import classnames from "classnames";
import domains from "../../../configuration/domains";
import Heading from "../../atoms/Heading";
import LightboxFn from "../../molecules/LightboxFn";
import Page from "../../organisms/Page";
import SuperCard from "../../molecules/SuperCard";
import TechCard from "../../molecules/TechCard";
import Sidebar from "../../molecules/Sidebar";
//import { documentToJSX } from "../../../helpers/Contentful/documentToJSX";
import { getImgResponsiveSet } from "../../../helpers/Contentful/images";

import * as p from "../../organisms/Page/styles.module.scss";

const { baseUrl } = domains;
/*

            capturingDetails {
                raw
            }
            processingDetails {
                raw
            }

*/

export const astrophotoQuery = graphql`
    query astrophotoQuery($slug: String!) {
        contentfulAstrophoto(slug: { eq: $slug }) {
            id
            title
            date
            type
            target
            slug
            link
            shortDescription
            description {
                id
                description
            }
            techCard {
                id
                cameras
                mounts
                filters
                software
                locations
                telescopes
                lenses
                calibFrames
                integration
                lightFrames
                imageQuality
                correctingLenses
                sensorCoolingTemp
            }
            image {
                description
                file {
                    url
                }
            }
        }
    }
`;

export default function Astrophoto(props) {
    const [cardIndex, setCardIndex] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    function callbackSCardFn(index) {
        setIsOpen(true);
        setCardIndex(index);
    }

    const entry = props.data.contentfulAstrophoto;
    if (entry === null || typeof entry === "undefined") return null;
    const {
        link,
        image,
        //capturingDetails,
        //processingDetails,
        title,
        type,
        target,
        shortDescription,
        description,
        techCard,
    } = entry;

    const imgResponsiveSet = getImgResponsiveSet(image.file.url);
    entry.imgResponsiveSet = imgResponsiveSet;
    const imgDescription = image ? image.description : "";

    return (
        <Page
            metaTitle={title}
            metaDescription={shortDescription}
            heroImages={[
                imgResponsiveSet.mobile.jpg, // 768 Mobile
                imgResponsiveSet.tablet.jpg, // 992 Tablet
                imgResponsiveSet.desktop.jpg, // 2880 Desktop
            ]}
            openGraph={{ img: imgResponsiveSet.mobile.jpg }}
            canonicalUrl={`${baseUrl}${link}`}
        >
            <div className={p.containerWrapper}>
                <div className={p.container}>
                    <LightboxFn
                        cards={[entry]}
                        cardIndex={cardIndex}
                        isOpen={isOpen}
                        displayTitle={false}
                        displayCaption={false}
                        setCardIndex={setCardIndex}
                        setIsOpen={setIsOpen}
                    />
                    <SuperCard
                        index={0}
                        imgResponsiveSet={imgResponsiveSet}
                        imgDescription={imgDescription}
                        callback={callbackSCardFn}
                    />
                    <Heading level={1} size="large" className={p.heading}>
                        {title}
                    </Heading>
                    {target ? (
                        <div
                            className={classnames(p.target, {
                                [p.deepSky]: type === "Deep Sky",
                                [p.starScape]: type === "Starscape",
                                [p.solarSystem]: type === "Solar System",
                                [p.events]: type === "Events",
                            })}
                        >
                            {target}
                        </div>
                    ) : null}
                    <p>{description && description.description}</p>
                    {/*capturingDetails?.raw && (
                        <>
                            <Heading
                                level={2}
                                size="medium"
                                className={p.mediumHeading}
                            >
                                Capturing the Light
                            </Heading>
                            {documentToJSX(JSON.parse(capturingDetails.raw))}
                        </>
                    )*/}
                    {/*processingDetails?.raw && (
                        <>
                            <Heading
                                level={2}
                                size="medium"
                                className={p.mediumHeading}
                            >
                                Processing Details
                            </Heading>
                            {documentToJSX(JSON.parse(processingDetails.raw))}
                        </>
                    )*/}
                    <Heading
                        level={2}
                        size="medium"
                        className={p.mediumHeading}
                    >
                        Tech Card
                    </Heading>
                    <TechCard
                        locations={techCard.locations}
                        cameras={techCard.cameras}
                        sensorCoolingTemp={techCard.sensorCoolingTemp}
                        filters={techCard.filters}
                        telescopes={techCard.telescopes}
                        lenses={techCard.lenses}
                        correctingLenses={techCard.correctingLenses}
                        imageQuality={techCard.imageQuality}
                        mounts={techCard.mounts}
                        lightFrames={techCard.lightFrames}
                        integration={techCard.integration}
                        calibFrames={techCard.calibFrames}
                        software={techCard.software}
                    />
                </div>
                <Sidebar />
            </div>
        </Page>
    );
}
