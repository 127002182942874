// extracted by mini-css-extract-plugin
export var inputGroupSm = "styles-module--input-group-sm--1ha2-";
export var inputGroupLg = "styles-module--input-group-lg--3ULId";
export var superCard = "styles-module--super-card--1rVpJ";
export var actions = "styles-module--actions--d7LKB";
export var link = "styles-module--link--bkS8V";
export var action = "styles-module--action--1xH0W";
export var heading = "styles-module--heading--2VF-f";
export var image = "styles-module--image--S8fqr";
export var content = "styles-module--content--1sfIh";
export var text = "styles-module--text--1zrVn";