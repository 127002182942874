import React from "react";
import classnames from "classnames";
import Expand from "../../atoms/Expand";
import { isSafari } from "../../../helpers/Browser";

// import { FacebookIcon, FacebookShareButton } from "react-share";
import { ISuperCardProps } from "./types";

import * as s from "./styles.module.scss";

export default function SuperCard(props: ISuperCardProps) {
    const {
        index = 0,
        imgResponsiveSet,
        imgDescription,
        callback,
        share = false,
    } = props;

    return (
        <div className={s.superCard} onClick={() => callback(index)}>
            {!isSafari && (
                <picture>
                    <source
                        srcSet={imgResponsiveSet.mobile.webp}
                        media="(max-width: 767px)"
                    />
                    <source
                        srcSet={imgResponsiveSet.mobile.jpg}
                        media="(max-width: 767px)"
                    />
                    <source
                        srcSet={imgResponsiveSet.tablet.webp}
                        media="(max-width: 991px)"
                    />
                    <source
                        srcSet={imgResponsiveSet.tablet.jpg}
                        media="(max-width: 991px)"
                    />

                    <source srcSet={imgResponsiveSet.desktop.webp} />
                    <img
                        src={imgResponsiveSet.desktop.jpg}
                        alt={imgDescription}
                    />
                </picture>
            )}

            {isSafari && (
                <img src={imgResponsiveSet.desktop.jpg} alt={imgDescription} />
            )}

            <div className={s.actions}>
                <div className={classnames("col-xs-6", s.action)}>
                    {/* share && (
                        <FacebookIcon size={32} round={true}>
                            <FacebookShareButton />
                        </FacebookIcon>
                    )*/}
                </div>
                <div className={classnames("col-xs-6", s.action)}>
                    <Expand
                        superCard={true}
                        index={index}
                        callbackFn={callback}
                    />
                </div>
            </div>
        </div>
    );
}
